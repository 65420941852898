<template>
  <div class="content">
    <PageHeader :title="$t('sms.send-msg.tabs.groups.title')" />
    <div class="page-content container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <div class="row">
                <div class="col-md-6 col-sm-12 col-lg-6">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <button
                        v-modal="{ target: 'create-group' }"
                        class="btn btn-success btn-block"
                      >
                        <i class="icon dripicons-plus"></i
                        ><span class="hide-m"> {{$t('generic-str.new-group')}}</span>
                      </button>
                      <button
                        class="btn btn-danger btn-remove border-radius-right"
                        @click="deleteAll"
                        v-bind:disabled="selectedGroups.length == 0"
                      >
                        <i class="icon dripicons-trash color-white"></i
                        ><span class="hide-m">{{$t('generic-str.delete')}}</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body block-el p-0">
              <div class="responsive-table">
                <div
                  v-if="!groups.length && fetched"
                  class="text-center table-placeholder"
                >
                  <i class="fas fa-users font-size-80"></i>
                  <h5 class="card-title m-t-20">{{$t('contacts-component.none')}}</h5>
                  <p>{{$t('contacts-component.register')}}</p>
                </div>
                <table v-if="fetched" class="table">
                  <thead v-if="groups.length" class="bg-light">
                    <tr>
                      <th class="w-60" scope="col">
                        <div class="custom-control custom-checkbox">
                          <input
                            v-model="selectAllGroups"
                            type="checkbox"
                            class="custom-control-input"
                            id="customCheckGAll"
                          />
                          <label
                            class="custom-control-label"
                            for="customCheckGAll"
                          ></label>
                        </div>
                      </th>
                      <th scope="col">{{$tc('generic-str.name', 1)}}</th>
                      <th scope="col">{{$tc('sms.send-msg.tabs.contact.title', 2)}}</th>
                      <th scope="col">{{$t('generic-str.info')}}</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(group, i) in groups" :key="group.id">
                      <td data-label="">
                        <div class="custom-control custom-checkbox">
                          <input
                            v-model="selectedGroups"
                            type="checkbox"
                            class="custom-control-input"
                            :id="'group-check' + i"
                            :value="group"
                          />
                          <label
                            class="custom-control-label"
                            :for="'group-check' + i"
                          ></label>
                        </div>
                      </td>
                      <td data-label="Nome">{{ group.name }}</td>
                      <td data-label="Contatos">{{ group.contacts_count }}</td>
                      <td data-label="Informações">{{ group.description }}</td>
                      <td data-label="Nome">
                        <div class="dropdown">
                          <a
                            role="button"
                            class="dropdown-action"
                            :to="'#menu-dropdown-' + i"
                            type="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <i class="icon dripicons-gear text-success"></i>
                          </a>
                          <div
                            class="dropdown-menu dropdown-menu-right"
                            :id="'menu-dropdown-' + i"
                            x-placement="bottom-end"
                            style=""
                          >
                            <a
                              class="dropdown-item"
                              v-modal="{ target: 'group-modal', data: group }"
                              >{{$t('generic-str.edit')}}</a
                            >
                            <a class="dropdown-item" @click="remove(group.id)"
                              >{{$t('generic-str.remove')}}</a
                            >
                            <router-link
                              class="dropdown-item"
                              :to="`groups/${group.id}`"
                              >{{$tc('sms.send-msg.tabs.contact.title', 2)}}
                            </router-link>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div
                  v-else
                  class="qt-block-ui relative"
                  style="padding: 120px"
                />
                <br />
                <pagination :lastPage="pages" @change="fetch" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <create-group-modal
      id="create-group"
      @sent="fetch(form.page)"
    ></create-group-modal>
    <group-modal id="group-modal" @sent="fetch(form.page)"></group-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import PageHeader from '@/components/PageHeader.vue';
import ContactService from '@/services/contact.service';
import Swal from 'sweetalert2';
import Pagination from '@/components/Pagination.vue';
import CreateGroupModal from '@/components/groups/CreateGroupModal.vue';
import GroupModal from '@/components/groups/GroupModal.vue';

export default {
  name: 'SmsDashboard',
  components: {
    PageHeader,
    Pagination,
    CreateGroupModal,
    GroupModal,
  },
  data() {
    return {
      fetched: false,
      groups: [],
      form: {
        name: '',
        page: 1,
      },
      pages: 1,
      selectedGroups: [],
    };
  },
  computed: {
    selectAllGroups: {
      get() {
        if (this.groups) {
          return this.selectedGroups.length === this.groups.length;
        }
        return false;
      },
      set(value) {
        const selected = [];

        if (value) {
          this.groups.forEach((client) => {
            selected.push(client);
          });
        }

        this.selectedGroups = selected;
      },
    },
  },
  created() {
    this.fetch(1);
  },
  methods: {
    fetch(page) {
      this.form.page = page;
      this.fetched = false;
      ContactService.getGroups(this.form).then(
        (response) => {
          this.fetched = true;
          this.groups = response.data;
          this.pages = response.last_page;
        },
        (error) => {
          this.content = error;
        },
      );
    },
    contacts(g) {
      this.$router.push({ name: 'GroupContacts', params: { group: g } });
    },
    remove(id) {
      Swal.fire({
        title: this.$t('generic-str.r-sure'),
        text:
          this.$t('contact-list-component.groups.warning'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('warnings.btn-y-delete'),
      }).then((result) => {
        if (result.isConfirmed) {
          ContactService.deleteGroup(id).then(
            () => {
              this.fetch(this.form.page);
              Swal.fire(`${this.$t('generic-str.deleted')}!`, this.$t('contact-list-component.groups.deleted'), 'success');
            },
            (error) => {
              this.content = error;
            },
          );
        }
      });
    },
    deleteAll() {
      Swal.fire({
        title: this.$t('generic-str.r-sure'),
        text:
          this.$t('contact-list-component.groups.warning-2'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('generic-str.yes'),
      }).then((result) => {
        if (result.value) {
          const groupsToDelete = [];
          this.selectedGroups.forEach((group) => {
            groupsToDelete.push(group.id);
          });
          ContactService.deleteGroups({ ids: groupsToDelete }).then(
            () => {
              // this.hide();
              this.fetch(this.form.page);
              Swal.fire(`${this.$t('generic-str.deleted')}!`, this.$t('contact-list-component.groups.deleted-2'), 'success');
            },
            (error) => {
              this.$toast.show({
                title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
                content: error.response.data.message,
                type: 'danger',
              });
            },
          );
        }
      });
    },
  },
  mounted() {
    this.$root.$on('groups.selected', this.selectedGroupsChange);
  },
};
</script>

<style scoped lang="scss">
.custom-control-label {
  &::after,
  &::before {
    top: -0.5rem;
  }
}
.btn i {
  line-height: 1;
  color: inherit;
}
</style>
